<!-- 店铺详情页 -->
<template>
  <!-- <div :class="this.storeId == 0 ?'content' : 'moduleContent'" > -->
  <div class="content">
    <div class="shell">
      <!-- <nav-bar title="门店详情" v-if="this.storeId == 0"  @click-left="goBack"/> -->
      <nav-bar
        title="门店详情"
        @click-left="goBack"
        v-if="this.storeId == 0"
        :left-arrow="isWeixin_status == false"
      />
      <Swipe :autoplay="3000" indicator-color="white" :height="180">
        <SwipeItem v-for="(image, index) in form.cover_url" :key="index">
          <img v-lazy="image" />
        </SwipeItem>
      </Swipe>
      <!-- 资料卡 -->
      <div class="dataCard">
        <div class="card">
          <Cell :title="form.name" size="large">
            <template #label>
              <div class="cardLabel">
                <div class="">
                  <Rate
                    v-model="rateValue"
                    :size="10"
                    color="#fe7c3e"
                    void-icon="star"
                    void-color="#eee"
                  />
                  5.0
                </div>
                <div>总订单 {{ form.order_num }}</div>
              </div>
            </template>
            <!-- <template #right-icon>
              <div class="rightIcon" @click="share">
                <img src="@static/vip/share.png" alt="" class="itemIncon">
                <div>分享</div>
              </div>
            </template> -->
          </Cell>
          <Cell :title="form.address" icon="location-o" @click="goAddress">
            <template #right-icon>
              <img src="@static/vip/address.png" alt="" class="itemIncon" />
              <div v-if="diatance && diatance != 'NaN'">{{ diatance }}m</div>
              <div></div>
            </template>
            <template #title>
              <div class="textAddress">{{ form.address }}</div>
              <!-- <Icon name="search" class="search=icon" /> -->
            </template>
          </Cell>
        </div>
      </div>
      <!-- 服務項目 -->
      <Cell value="门店服务" />
      <Collapse v-model="activeNames" v-if="serveList.length != 0">
        <Collapse-item title="洗车" name="1">
          <Radio-group v-model="choseServe" @change="radioChange">
            <Cell-group>
              <Cell clickable v-for="(item, index) in serveList" :key="index">
                <template #label>
                  <div v-html="item.des"></div>
                </template>
                <template #title>
                  <Radio :name="item.id" checked-color="#fe7c3e">{{
                    item.name
                  }}</Radio>
                </template>
                <template #right-icon>
                  <div class="priceBox">
                    <div>
                      ￥<span>{{ item.score }}积分</span>
                    </div>
                    <div>门店价格￥{{ item.score * 1 + 30 }}积分</div>
                  </div>
                </template>
              </Cell>
            </Cell-group>
          </Radio-group>
        </Collapse-item>
      </Collapse>
      <Cell value="暂无数据" v-else />
    </div>
    <!-- <Submit-bar 
      button-text="去结算" 
      @submit="onSubmit" 
      :price="200" 
      text-align="left" 
      button-type="warning"
      suffix-label="">
    </Submit-bar> -->
    <div class="subBottom">
      <div class="price">
        <div>
          ￥
          {{ showData.price }}
        </div>
        <div>{{ showData.name }}</div>
      </div>
      <Button type="warning" @click="pay">去结算</Button>
    </div>
  </div>
</template>

<script>
import washCar from "@api/washCar";
import { getDistance } from "@/utils/tools";
import wx from "weixin-js-sdk";
import { goWxConfigJsApi } from "@/utils/wxpay";

import {
  navBar,
  Swipe,
  SwipeItem,
  Cell,
  CellGroup,
  Rate,
  Collapse,
  CollapseItem,
  Radio,
  RadioGroup,
  Button,
} from "vant";
export default {
  components: {
    navBar,
    Swipe,
    SwipeItem,
    Cell,
    CellGroup,
    Rate,
    Collapse,
    Radio,
    RadioGroup,
    CollapseItem,
    Button,
  },
  props: {
    storeId: {
      default: "0",
    },
  },
  data() {
    return {
      // 有两种情况，一个是作为组件接收id值，而是作为页面
      pageId: 0,
      newStoreId: this.storeId,
      form: {},
      diatance: "",
      value: "",
      rateValue: 5,
      serveList: [],
      activeNames: ["1"],
      choseServe: "", //
      showData: {
        price: "00.00",
        name: "",
      },
    };
  },

  created() {
    if (this.isWeixin_status) {
      goWxConfigJsApi("openLocation,getLocation");
      setTimeout(() => {
        this.setDistance();
      }, 500)
    }
  },
  methods: {
    share() {
      if (this.isWeixin_status) {
        let url = window.location.href;
        wx.ready(function() {
          //需在用户可能点击分享按钮前就先调用
          wx.updateAppMessageShareData({
            title: "您的好友分享给你一个洗车门店", // 分享标题
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "", // 分享图标
            success: function(res) {
              // 设置成功
              console.log("res", res);
            },
          });
        });
      }
    },
    radioChange(value) {
      this.transitionName(value);
    },
    transitionName(value) {
      this.serveList.forEach((item) => {
        if (item.id == value) {
          this.showData.price = item.score;
          this.showData.name = item.name;
        }
      });
    },
    goAddress() {
      if (this.isWeixin_status) {
        wx.openLocation({
          latitude: this.form.lat, // 纬度，浮点数，范围为90 ~ -90
          longitude: this.form.lng, // 经度，浮点数，范围为180 ~ -180。
          name: this.form.address, // 位置名
          address: "", // 地址详情说明
          scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      } else {
        this.$toast("请在微信环境中打开...");
      }
    },
    pay() {
      if (!this.choseServe) {
        this.$toast("请选择需要的服务项目！！");
        return;
      }
      washCar.getStoreServePay(this.choseServe).then((res) => {
        // console.log("店铺详情", res);
        this.$toast(res.msg);

        if (res.code == 200) {
          this.$router.push("/washCarOrder");
        }
      });
    },
    getList(id) {
      washCar.getStoreDetail(id).then((res) => {
        // console.log("店铺详情", res);
        if (res.code == 200) {
          this.form = res.data;
          this.diatance = getDistance(
            this.form.lat,
            this.form.lat,
            this.latitude,
            this.longitude
          );
          washCar.getStoreServeList(id).then((res) => {
            if (res.code == 200) {
              this.serveList = res.data.result;
              this.transitionName(this.choseServe);
            }
          });
        }
      });
    },
    // 计算两地之间距离
    setDistance() {
      var _this = this;
      wx.getLocation({
        type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function(res) {
          _this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          _this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          _this.$toast.clear();
        },
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (localStorage.getItem("washStatus") == 1) {
        let roomid = localStorage.getItem("pageId");
        this.getList(roomid);
        this.choseServe = localStorage.getItem("choseServe") * 1;
      } else {
        let newStore = this.storeId;
        if (newStore == 0) {
          if (this.$route.query.storeId != "undefined") {
            this.pageId = this.$route.query.storeId;
            this.getList(this.pageId);
          } else {
            this.$toast("非法访问");
            this.$router.push("/");
          }
        } else {
          this.getList(newStore);
        }
      }
    });
  },
};
</script>
<style lang="scss" scoped>
/deep/.van-swipe-item {
  > img {
    width: 100%;
  }
}
/*  */
.moduleContent {
  width: 100%;
  height: calc(100vh - 46px);
  display: flex;
  flex-direction: column;
}
.content {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .shell {
    flex: 1;
    overflow: auto;
    .dataCard {
      height: 110px;
      background: #f7f7f7;
      position: relative;
      margin: 0 auto;
      .card {
        width: 90%;
        margin: 0 20px;
        position: absolute;
        top: -20px;
        right: 0;
        left: 0;
        border: 1px solid #f2f2f2;
        border-radius: 6px;
        /deep/ .van-cell:first-child {
          border-radius: 6px 6px 0 0;
        }
        /deep/ .van-cell:last-child {
          border-radius: 0 0 6px 6px;
          font-size: 12px;
          /deep/.textAddress {
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        /* label */
        .cardLabel {
          display: flex;
          font-size: 10px;
          > div {
            margin-right: 2px;
          }
        }
        .rightIcon {
          text-align: center;
          font-size: 12px;
        }
        .itemIncon {
          height: 16px;
        }
      }
    }
    /* 门店服务 */
    .priceBox {
      text-align: right;
      div:first-child {
        color: #e3575e;
        font-weight: 550;
      }
      div:last-child {
        color: #969799;
        text-decoration: line-through;
      }
    }
  }
  /* 提交按鈕 */
  .subBottom {
    width: 100%;
    height: 60px;
    background: #fff;
    border-top: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  .subBottom .price {
    div:first-child {
      color: #e35754;
      font-size: 14px;
      font-weight: 550;
    }
  }
  .subBottom .van-button {
    padding: 0px 40px;
  }
}
</style>
